<template>
  <v-avatar>
    <img
      v-if="photo"
      :src="photo"
      :alt="name"
    >
    <v-icon v-else>
      mdi-account-circle
    </v-icon>
  </v-avatar>
</template>
<script>
  import Avatar from 'vue-avatar'
  export default {
    name: 'Account',
    components: {
      Avatar,
    },
    data () {
      return {
        photo: '',
        userId: '',
        name: '',
        email: '',
      }
    },
    created () {

    },
    mounted () {
      this.user = this.$firebaseAuth.currentUser
      this.name = this.user.displayName
      this.email = this.user.email
      this.photo = this.user.photoURL
      this.userId = this.user.uid
    },
    methods: {

    },
  }
</script>
